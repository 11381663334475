import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>WIP</p>
        <div className="machine-container">
          <button className="cta-button mint-button" onClick={() => {}} >ギフトを取得する</button>
        </div>
      </header>
    </div>
  );
}

export default App;
